
export const getOktaDetails = () => {
    const oktaToken = localStorage.getItem("sensing-okta-token");
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const oktaTokenObj = JSON.parse(oktaToken);
    if (oktaTokenObj && oktaTokenObj.accessToken !== undefined) {
        return oktaTokenObj;
    }
    return null;
};

export const getOktaToken = () => {
    const oktaTokenDetails = getOktaDetails();
    if (oktaTokenDetails !== null) {
        return oktaTokenDetails.accessToken.accessToken;
    }
    return "";
};

export const getUsername = () => {
    const oktaTokenDetails = getOktaDetails();
    const userNamearray =
      oktaTokenDetails?.idToken?.claims?.preferred_username.split("@")[0];
    const userName = userNamearray;
    return userName;
}