import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { Button, Loader } from '@gitlab-rtsensing/component-library';
import { motion, AnimatePresence } from 'framer-motion';
import Logo from '../../assets/images/sensing.png';

import './index.scss';
import RequestAccessModal from '../request-access-modal';

interface TransitionWaterfallProps {
  transitionActive: boolean;
  transitionError: boolean;
}

type AdminMessage = {
  action: 'access request successful';
};

/**
 * Animated transition which will render the component in, and then will animate the component
 * out once the parent stops the transition
 * @param {function} transitionActive - Controller set by the parent to mount/unmount the
 * transition and trigger entry/exit animations
 */
const TransitionWaterfall = (props: TransitionWaterfallProps) => {
  const [requestAccessModalOpen, setRequestAccessModalOpen] = useState(false);
  const { transitionActive, transitionError } = props;
  const contentRoot =
    document.getElementsByTagName('body')[0] ?? ({} as Element);

  const [accessRequestSuccessful, setAccessRequestSuccessful] = useState(false);

  function handleRequestAccessClick() {
    setRequestAccessModalOpen(true);

    const abortController = new AbortController();
    window.addEventListener(
      'message',
      (event: MessageEvent<AdminMessage>) => {
        if (
          event.origin !== window.location.origin ||
          event.data.action !== 'access request successful'
        ) {
          return;
        }
        abortController.abort();
        setAccessRequestSuccessful(true);
      },
      { signal: abortController.signal },
    );
  }

  return createPortal(
    <>
      <AnimatePresence>
        {transitionActive && (
          <>
            <motion.div
              key="backdrop-content"
              className="rtsensing-auth-transition-content-container"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, transition: { delay: 0.8 } }}
            >
              <motion.div
                key="waterfall-logo-wrapper"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.5 } }}
                exit={{ opacity: 0, transition: { delay: 0.5 } }}
                className="waterfall-logo-wrapper"
              >
                <motion.img
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { delay: 0.5 } }}
                  exit={{ opacity: 0, transition: { delay: 0.5 } }}
                  src={Logo}
                  alt={'amgen-logo'}
                  className="waterfall-logo"
                />
                {!transitionError && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 0.3, transition: { delay: 1 } }}
                    exit={{ opacity: 0, transition: { delay: 0.5 } }}
                    className="waterfall-loader"
                  >
                    <Loader />
                  </motion.div>
                )}
                {transitionError && (
                  <motion.div
                    key="error-message"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="waterfall-error"
                  >
                    <p className="waterfall-error-message waterfall-error-message--title">
                      Unable to Sign In!
                    </p>
                    <p className="waterfall-error-message waterfall-error-message--paragraph">
                      We are unable to sign you in at this time - Please try
                      again or report your issue for assistance
                    </p>
                    <div className="waterfall-error-options">
                      <Button
                        label="Report Error"
                        type="secondary"
                        onClick={() => setRequestAccessModalOpen(true)}
                      />
                      <Button
                        label="Try Again"
                        type="secondary"
                        onClick={() => window.location.reload()}
                      />
                    </div>
                  </motion.div>
                )}
              </motion.div>
            </motion.div>
            <motion.div
              key="backdrop"
              className="rtsensing-auth-transition-wave-container"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, transition: { delay: 0.8 } }}
            >
              <motion.div
                key="wave-1"
                className="list-body"
                initial={{ opacity: 0, x: '-100vw' }}
                animate={{ opacity: 1, x: '0' }}
                exit={{ opacity: 0, x: '-100vw' }}
                transition={{
                  delay: 0.5,
                  default: { ease: 'linear' },
                }}
              />
              <motion.div
                key="wave-2"
                className="list-body"
                initial={{ opacity: 0, x: '-100vw' }}
                animate={{ opacity: 1, x: '0' }}
                exit={{ opacity: 0, x: '-100vw' }}
                transition={{
                  delay: 0.4,
                  default: { ease: 'linear' },
                }}
              />
              <motion.div
                key="wave-3"
                className="list-body"
                initial={{ opacity: 0, x: '-100vw' }}
                animate={{ opacity: 1, x: '0' }}
                exit={{ opacity: 0, x: '-100vw' }}
                transition={{
                  delay: 0.3,
                  default: { ease: 'linear' },
                }}
              />
              <motion.div
                key="wave-4"
                className="list-body"
                initial={{ opacity: 0, x: '-100vw' }}
                animate={{ opacity: 1, x: '0' }}
                exit={{ opacity: 0, x: '-100vw' }}
                transition={{
                  delay: 0.2,
                  default: { ease: 'linear' },
                }}
              />
              <motion.div
                key="wave-5"
                className="list-body"
                initial={{ opacity: 0, x: '-100vw' }}
                animate={{ opacity: 1, x: '0' }}
                exit={{ opacity: 0, x: '-100vw' }}
                transition={{
                  delay: 0.1,
                  default: { ease: 'linear' },
                }}
              />
            </motion.div>
          </>
        )}
      </AnimatePresence>
      {requestAccessModalOpen && (
        <RequestAccessModal
          opened={requestAccessModalOpen}
          onRequestAccessModalClosed={() => setRequestAccessModalOpen(false)}
          accessRequestSuccessful={accessRequestSuccessful}
        />
      )}
    </>,
    contentRoot,
  );
};

export default TransitionWaterfall;
