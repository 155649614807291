import { OktaAuth } from '@okta/okta-auth-js';
import axios from 'axios';
import { getOktaToken, getUsername } from './okta-token-manager';
import { AuthPermissions } from './types';

export const oktaAuthConfig = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_ISSUER_LINK,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/implicit/callback',
  scopes: ['openid', 'profile', 'email', 'offline_access'],
  pkce: true,
  responseType: 'code',
  tokenManager: {
    expireEarlySeconds: 120,
    autoRenew: true,
    storageKey: 'sensing-okta-token',
  },
});

export const logout = (oktaAuthConfig: any) => {
  oktaAuthConfig.signOut({
    postLogoutRedirectUri: `${window.location.origin}/logout/callback`,
  });
};

export const setEventOnExpired = (oktaAuthConfig: any) => {
  oktaAuthConfig.tokenManager.off('expired');
  // Triggered when a token has expired
  oktaAuthConfig.tokenManager.on(
    'expired',
    function (key: any, expiredToken: any) {
      console.log('Token with key', key, ' has expired:');
      oktaAuthConfig.tokenManager.renew('accessToken');
      oktaAuthConfig.tokenManager.renew('idToken');
    },
  );
};

export const fetchPermission = async (): Promise<
  { permissions: AuthPermissions; userName: any } | null | undefined
> => {
  let permissions: AuthPermissions = {};
  const _nameofuser = 'username';
  const userName: string = getUsername();
  const nameOfUser = userName;
  const oktaToken = getOktaToken();
  const graphQLQuery = `
  query {
    sensingAuthorization(
      ${_nameofuser}: "${nameOfUser}", 
      workstreams: ["home"],
      skipWorkstreamLevelAuth: false
    ) {
      horizon
      external
      finance
      people
      brand
      pipeline
      supply
      admin
      curation
      prioritizedAgenda
      notificationsSubscriptions
      notifications
      notificationsCurator
      notificationsConfigure
      notificationsReporting
      home 
      homeAuthorization {
        people
        brand
        finance
        prioritizedAgenda
        pipeline
        external
        supply
        keyEvents
        horizonEvents
        experimentalQna
        aboutEdit
        aboutValue
        keyEvents
      }
    }
  }
`;
  const payload = { query: graphQLQuery, variables: null };
  try {
    const res = await axios({
      method: 'post',
      url: process.env.REACT_APP_AUTH_LINK,
      data: payload,
      headers: {
        Authorization: `Bearer ${oktaToken}`,
      },
    });
    if (res.status === 200 && res.data) {
      const permData = res.data.data.sensingAuthorization;
      permissions = {
        ...permData,
        home: true,
        about: true,
      };
      return { permissions, userName };
    } else {
      return null;
    }
  } catch (error) {
    console.log(
      'Failed to get the permissions, Please connect Admin of the application.',
      error,
    );
    return null;
  }
};
